import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class InlandMarineInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Inland Marine Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Inland Marine insurance specializes in insuring high-value products and equipment that are shipped, but are excluded from a typical property coverage policy. Contact us to get an insurance quote for your Louisiana business." }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Construction Equipment" />
        <Callout
          title="Inland Marine Insurance"
          content="If your company in Louisiana ships products and equipment frequently, inland marine insurance is worth considering for your business. Inland Marine insurance specializes in insuring high-value products and equipment that are shipped, but are excluded from a typical property coverage policy."
        />
        <div className="site-body page-content">
          <h2>What Inland Marine Insurance Usually Covers</h2>
          <p>
          <ul>
          <li>Property in transit, such as computers, communication equipment, fine art and billboards.</li>
          <li>Property held by a bailee.</li>
          <li>Instrumentality of transportation and communication.</li>
          <li>Certain types of movable property such as medical equipment and contractors equipment.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="23 million packages are stolen every year in the United States."
            source="Bank Rate"
          />
          <h2>What Inland Marine Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Items that have not yet been shipped.</li>
          <li>Items that are damaged after they have been shipped.</li>
          <li>Damage to construction sites before construction has started.</li>
          <li>Intentional damage to property.</li>
          </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="an Inland Marine Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default InlandMarineInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "inland-marine-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-17.jpg" }) {
      ...mainBg
    }
  }
`;
